<script>
import axios from 'axios';
import Swal from 'sweetalert2';
export default {
  props: ['id'],
  data() {
    return {
      level: 0,
      formData: {
        timer: '',
        output_soal_pg: '',
        output_soal_bs: '',
        output_soal_mj: '',
        output_soal_pgsk: '',
        output_soal_text: '',
        output_soal_bssk: '',
      },
    };
  },
  mounted() {
    this.getDataById();
  },
  methods: {
    submitForm() {
      const authToken = localStorage.access_token;
      axios
        .put(
          process.env.VUE_APP_BACKEND_URL_VERSION + 'master/timer/' + this.id,
          this.formData,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
              'Content-Type': 'application/json',
            },
          }
        )
        .then((response) => {
          console.log('Response:', response.data);
          this.resetForm();
          window.location.reload();
        })
        .catch((error) => {
          // Handle errors
          console.error('Error:', error.response.data);
        });
    },
    getDataById() {
      let url =
        process.env.VUE_APP_BACKEND_URL_VERSION + 'master/timer/' + this.id;

      let self = this;

      var config = {
        method: 'get',
        url: url,
        params: self.tableData,
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + localStorage.access_token,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          let response_data_fix = response_data.data;
          if (response_data.meta.code == 200) {
            var data = response_data_fix;
            self.level = data.level_idlevel;
            self.formData.timer = data.timer;
            self.formData.output_soal_pg = data.output_soal_pg;
            self.formData.output_soal_bs = data.output_soal_bs;
            self.formData.output_soal_mj = data.output_soal_mj;
            self.formData.output_soal_pgsk = data.output_soal_pgsk;
            self.formData.output_soal_text = data.output_soal_text;
            self.formData.output_soal_bssk = data.output_soal_bssk;
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: response_data_fix.data.message,
            });
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    resetForm() {
      this.formData.timer = '';
      this.level = 0;
      this.formData.output_soal_pg = '';
      this.formData.output_soal_bs = '';
      this.formData.output_soal_mj = '';
      this.formData.output_soal_pgsk = '';
      this.formData.output_soal_text = '';
      this.formData.output_soal_bssk = '';
    },
  },
};
</script>

<style scoped>
/* Add your custom styles here if needed */
</style>

<template>
  <div class="container">
    <form @submit.prevent="submitForm" class="needs-validation" novalidate>
      <div class="mb-3">
        <label for="timer" class="form-label">Output Timer (menit)</label>
        <div class="input-group">
          <input
            v-model="formData.timer"
            class="form-control"
            id="timer"
            type="number"
            required
          />
          <span class="input-group-text"> menit </span>
        </div>
      </div>
      <div class="row mb-3">
        <div class="12">
          <label for="pilihan_ganda" class="form-label">
            Output Pilihan Ganda
          </label>
          <div class="input-group">
            <input
              v-model="formData.output_soal_pg"
              class="form-control"
              id="pilihan_ganda"
              type="number"
              :disabled="level != 1 && level != 2"
              :required="level == 1 || level == 2"
            />
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="12">
          <label for="pilihan_ganda_sk" class="form-label">
            Output Pilihan Ganda Studi Kasus
          </label>
          <div class="input-group">
            <input
            v-model="formData.output_soal_pgsk"
            class="form-control"
            id="pilihan_ganda_sk"
            type="number"
            :disabled="level != 3"
            :required="level == 3"
            />
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="12">
          <label for="benar_salah" class="form-label">Output Benar Salah</label>
          <div class="input-group">
            <input
              v-model="formData.output_soal_bs"
              class="form-control"
              id="benar_salah"
              type="number"
              :disabled="level != 2"
              :required="level == 2"
            />
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="12">
          <label for="benar_salah_sk" class="form-label">
            Output Benar Salah Studi Kasus
          </label>
          <div class="input-group">
            <input
            v-model="formData.output_soal_bssk"
            class="form-control"
            id="benar_salah_sk"
            type="number"
            :disabled="level != 3"
            :required="level == 3"
            />
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="12">
          <label for="menjodohkan" class="form-label">Output Menjodohkan</label>
          <div class="input-group">
            <input
            v-model="formData.output_soal_mj"
            class="form-control"
            id="menjodohkan"
            type="number"
            :disabled="level != 2"
            :required="level == 2"
            />
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="12">
          <label for="text" class="form-label">Output Text</label>
          <div class="input-group">
            <input
              v-model="formData.output_soal_text"
              class="form-control"
              id="text"
              type="number"
              :disabled="level != 4"
              :required="level == 4"
            />
          </div>
        </div>
      </div>
      <div class="mb-3">
        <button type="submit" class="btn btn-primary">Submit</button>
      </div>
    </form>
  </div>
</template>
