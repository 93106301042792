<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import Datatable from "@/router/layouts/table-datatable.vue";
// import Pagination from "@/router/layouts/table-pagination.vue";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";
import ModalEditTimer from "./modal-edit-timer.vue";

export default {
  page: {
    title: "Konfigurasi Timer",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    Datatable,
    // Pagination,
    ModalEditTimer,
    // ModalEditJadwal,
  },
  data() {
    let sortOrders = []; // select sortOrder

    // definisi kolom yang dipakai
    let columns = [
      {
        width: "50px",
        label: "No",
        name: "-",
      },
      {
        width: "auto",
        label: "Level",
        name: "-",
      },
      {
        width: "auto",
        label: "Output Timer",
        name: "-",
      },
      {
        width: "auto",
        label: "Output Pilihan Ganda",
        name: "-",
      },
      {
        width: "auto",
        label: "Output Benar Salah",
        name: "-",
      },
      {
        width: "auto",
        label: "Output Menjodohkan",
        name: "-",
      },
      {
        width: "auto",
        label: "Output Pilihan Ganda Studi Kasus",
        name: "-",
      },
      {
        width: "auto",
        label: "Output Benar Salah Studi Kasus",
        name: "-",
      },
      {
        width: "auto",
        label: "Output Esai",
        name: "-",
      },
      {
        width: "auto",
        label: "Aksi",
        name: "-",
      }, // jika tidak ada sorting kasih name dengan karakter -
    ];

    // cek sorting
    columns.forEach((column) => {
      sortOrders[column.name] = -1;
    });
    // Get hak akses
    const menu = "Konfigurasi Timer";

    return {
      title: menu,
      isWrite: localStorage.getItem("isWrite"),
      showModalAdd: false,
      showModalEdit: false,
      selected_id: "ss",
      namePathUrl: this.$route.name,
      sessionRoleId: localStorage.session_role_active_id,
      loadingTable: true,
      table_data: [],
      columns: columns,
      sortKey: "id_role", // sort key awal
      sortOrders: sortOrders,
      currentTablePage: "",

      // parsing data ke table data
      tableData: {
        draw: 0,
        length: 10,
        search: "",
        column: 0,
        dir: "asc",
        filter_status: "all", // untuk filtering data
      },

      // config untuk paginasi
      pagination: {
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
        links: "",
      },
    };
  },
  async mounted() {
    let self = this;
    // self.cekAksesMenu();
    await self.getDataTable();
  },
  methods: {
    fullDateTimeFormat(datetime) {
      return moment(datetime).format("YYYY-MM-DD HH:mm");
    },
    // cekAksesMenu() {
    //   let self = this;
    //   var config_axios = {
    //     method: 'get',
    //     url: process.env.VUE_APP_BACKEND_URL_VERSION + 'auth/check-access-menu',
    //     params: {
    //       role_id: self.sessionRoleId,
    //       link_name: self.namePathUrl,
    //     },
    //     headers: {
    //       Accept: 'application/json',
    //       Authorization: 'Bearer ' + localStorage.access_token,
    //     },
    //   };
    //   axios(config_axios)
    //     .then((response) => {
    //       let response_data_fix = response.data.data.status_access;
    //       if (response.data.meta.code == 200) {
    //         if (response_data_fix == false) {
    //           Swal.fire({
    //             icon: 'warning',
    //             title: 'Oppss',
    //             text: 'Mohon maaf anda tidak diberikan akses pada halaman ini.',
    //             timer: 2000,
    //             timerProgressBar: true,
    //             showConfirmButton: false,
    //           }).then((result) => {
    //             /* Read more about handling dismissals below */
    //             if (result.dismiss === Swal.DismissReason.timer) {
    //               self.$router.push({
    //                 name: 'dashboard',
    //               });
    //             }
    //           });
    //         }
    //       } else {
    //         Swal.fire({
    //           icon: 'error',
    //           title: 'Oops...',
    //           text: response_data_fix.data.message,
    //         });
    //       }
    //     })
    //     .catch((errors) => {
    //       console.log(errors);
    //     });
    // },
    getDataTable(
      url = process.env.VUE_APP_BACKEND_URL_VERSION + "master/timer"
    ) {
      let self = this;
      self.loadingTable = true;
      self.tableData.draw++;

      var config = {
        method: "get",
        url: url,
        params: self.tableData,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          let response_data_fix = response_data.data;
          if (response_data.meta.code == 200) {
            console.log(response_data_fix);
            self.table_data = response_data_fix.data;
            self.configPagination(response_data_fix);
            self.currentTablePage = response_data_fix.current_page;
            self.loadingTable = false;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data_fix.data.message,
            });
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    configPagination(data) {
      let self = this;
      self.pagination.lastPage = data.last_page;
      self.pagination.currentPage = data.current_page;
      self.pagination.total = data.total;
      self.pagination.lastPageUrl = data.last_page_url;
      self.pagination.nextPageUrl = data.next_page_url;
      self.pagination.prevPageUrl = data.prev_page_url;
      self.pagination.from = data.from;
      self.pagination.to = data.to;
      self.pagination.links = data.links;
    },
    sortBy(key) {
      let self = this;
      self.sortKey = key;
      self.sortOrders[key] = self.sortOrders[key] * -1;
      self.tableData.column = self.getIndex(self.columns, "name", key);
      self.tableData.dir = self.sortOrders[key] === 1 ? "asc" : "desc";
      self.getDataTable();
    },
    getIndex(array, key, value) {
      return array.findIndex((i) => i[key] == value);
    },
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <div class="card-title">Konfigurasi Timer</div>
              </div>
              <div class="col-md-6"></div>
              <div class="col-md-12">
                <hr />
              </div>
              <datatable
                :columns="columns"
                :sortKey="sortKey"
                :sortOrders="sortOrders"
                @sort="sortBy"
              >
                <tbody>
                  <tr v-if="loadingTable">
                    <td colspan="6">
                      <i class="fas fa-spinner fa-spin"></i> Loading...
                    </td>
                  </tr>
                  <tr v-else-if="table_data == ''">
                    <td class="text-center" colspan="6">Data Tidak Tersedia</td>
                  </tr>
                  <tr
                    v-else
                    v-for="(row_data, key_data) in table_data"
                    :key="key_data"
                  >
                    <td class="text-center">
                      <div v-if="key_data + 1 == 10">
                        {{ currentTablePage }}0.
                      </div>
                      <div v-else>
                        {{
                          currentTablePage - 1 != 0 ? currentTablePage - 1 : ""
                        }}{{ key_data + 1 }}.
                      </div>
                    </td>
                    <td>
                      {{ row_data.nama_level }}
                    </td>
                    <td>{{ row_data.timer }} menit</td>
                    <td>
                      {{ row_data.output_soal_pg }}
                    </td>
                    <td>
                      {{ row_data.output_soal_bs }}
                    </td>
                    <td>
                      {{ row_data.output_soal_mj }}
                    </td>
                    <td>
                      {{ row_data.output_soal_pgsk }}
                    </td>
                    <td>
                      {{ row_data.output_soal_bssk }}
                    </td>
                    <td>
                      {{ row_data.output_soal_text }}
                    </td>
                    <td class="text-center">
                      <button
                        type="button"
                        class="btn btn-warning btn-sm"
                        v-if="isWrite == 1"
                        v-on:click="
                          showModalEdit = !showModalEdit;
                          selected_id = row_data.timer_id;
                        "
                      >
                        <i class="fa fa-edit"></i> Edit
                      </button>
                    </td>
                  </tr>
                </tbody>
              </datatable>
            </div>
            <!-- <pagination :pagination="pagination" @to-page="toPage"></pagination> -->
          </div>
        </div>
      </div>
    </div>
  </Layout>
  <b-modal v-model="showModalEdit" hide-footer title="Edit Konfigurasi Timer">
    <ModalEditTimer :id="selected_id" v-if="showModalEdit" />
  </b-modal>
</template>
